<template>
  <CardToolbar
    class="all-users"
    title="Accounts Management"
    :description="null"
    :show-toolbar-buttons="true"
    :padding="false"
  >
    <template v-slot:toolbar>
      <CardHeaderButtonAI
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"
        data-kt-menu-flip="top-end"
      >
        Filter
      </CardHeaderButtonAI>
      <!--begin::Menu 1-->
      <div
        class="menu menu-sub menu-sub-dropdown w-250px w-md-300px"
        data-kt-menu="true"
      >
        <div class="px-7 py-5">
          <div class="fs-5 text-dark fw-bolder">Filter Options</div>
        </div>
        <div class="separator border-gray-200"></div>
        <div class="px-7 py-5">
          <div class="mb-10">
            <label class="form-label fw-bold">Status:</label>
            <div>
              <el-select
                class="form-select-solid"
                placeholder="Select option"
                v-model="filterQuery"
              >
                <el-option
                  v-for="(status, key, index) in SearchQueryEnum"
                  :key="index"
                  :value="status"
                  :label="key"
                  >{{ key }}
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="d-flex justify-content-end">
            <button
              type="reset"
              class="btn btn-sm btn-white btn-active-light-primary me-2"
              data-kt-menu-dismiss="true"
            >
              close
            </button>
            <button
              @click="applyFilter"
              type="submit"
              class="btn btn-sm btn-primary"
              data-kt-menu-dismiss="true"
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:body>
      <el-table
        style="width: 100%"
        row-key="id"
        :fit="true"
        table-layout="fixed"
        stripe
        header-cell-class-name="table-header-text"
        :data="users"
        :row-class-name="tableRowClassName"
        :cell-class-name="tableCellClassName"
        @sort-change="sortResults"
        v-loading="loading"
      >
        <el-table-column sortable="custom" prop="email" label="User Email">
          <template #default="scope">
            {{ scope.row.email }}
          </template>
        </el-table-column>
        <el-table-column label="Category">
          <template #default="scope">
            {{ capitalizeFirstLetter(scope.row.category) }}
          </template>
        </el-table-column>
        <el-table-column label="Status">
          <template #default="scope">
            <span
              :class="scope.row.isApproved ? 'approved-label' : 'danger-label'"
            >
              {{ scope.row.isApproved ? "Approved" : "UnApproved" }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="Action">
          <template #default="scope">
            <button
              data-bs-toggle="modal"
              data-bs-target="#edit_user_modal"
              @click="openEditModal(scope.row)"
              class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
            >
              <svg
                version="1.1"
                viewBox="0 0 24 24"
                height="24px"
                width="24px"
                xmlns="http://www.w3.org/2000/svg"
              >
                <defs xmlns="http://www.w3.org/2000/svg"></defs>
                <g
                  xmlns="http://www.w3.org/2000/svg"
                  id="Stockholm-icons-/-Communication-/-Write"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <rect id="bound" x="0" y="0" width="24" height="24"></rect>
                  <path
                    d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z"
                    id="Path-11"
                    fill="#009EF7"
                    fill-rule="nonzero"
                    transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953) "
                  ></path>
                  <path
                    d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z"
                    id="Path-57"
                    fill="#009EF7"
                    fill-rule="nonzero"
                    opacity="0.3"
                  ></path>
                </g>
              </svg>
            </button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="mt-5"
        v-model:currentPage="localPagination.offset"
        v-model:page-size="localPagination.limit"
        :page-sizes="[5, 10, 20, 50]"
        :small="true"
        layout="total, sizes, prev, pager, next"
        :total="localPagination.total"
        @size-change="paginationChanged"
        @current-change="paginationChanged"
      >
      </el-pagination>
    </template>
  </CardToolbar>
  <div class="modal fade" id="edit_user_modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered mw-500px">
      <div class="modal-content">
        <div
          class="modal-header bg-light-grey"
          id="kt_modal_create_api_key_header"
        >
          <h2 class="card-heading">Update User Account</h2>
        </div>
        <div class="modal-body bg-light-grey scroll-y py-0">
          <!--begin::Input group-->
          <div class="fv-row mb-10">
            <!--begin::Label-->
            <label class="form-label fs-6 fw-bolder text-dark">Email</label>
            <!--end::Label-->

            <!--begin::Input-->
            <Field
              class="form-control form-control-lg form-control-solid"
              type="text"
              name="email"
              v-model="selectedUser.email"
              readonly
            />
            <!--end::Input-->
          </div>
          <!--end::Input group-->
          <!--begin::Select-->
          <div class="fv-row mb-7">
            <label class="form-label fw-bolder text-dark fs-6">Category</label>
            <Field
              class="form-control form-control-lg form-control-solid"
              name="category"
              as="select"
              v-model="selectedUser.category"
            >
              <option
                v-for="(category, key, index) in filteredCategory(Category)"
                :key="index"
                :value="category"
              >
                {{ key }}
              </option>
            </Field>
          </div>
          <!--end::Select group-->
          <div class="fv-row mb-7">
            <label class="form-label fw-bolder text-dark fs-6">Status</label>
            <br />
            <el-switch
              v-model="selectedUser.isApproved"
              class="mt-2"
              active-text="Approved"
              inactive-text="UnApproved"
              style="
                --el-switch-on-color: #13ce66;
                --el-switch-off-color: #ff4949;
              "
            />
          </div>
        </div>

        <div class="modal-footer flex-center bg-light-grey">
          <button
            type="reset"
            class="btn me-3 btn-primary"
            data-bs-dismiss="modal"
            @click="updateUser(selectedUser)"
          >
            Update
          </button>
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
            @click="selectedUser = {}"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CardToolbar from "@/common/components/CardToolbar";
import { onMounted, ref } from "vue";
import { Field } from "vee-validate";
import { paginate, sortArray } from "@/aiBetaBench/helpers";
import { Category } from "@/common/enums/categoryEnum";
import { SearchQueryEnum } from "@/common/enums/searchQueryEnum";
import { getAllUsers, updateUserAccount } from "@/api/user.api";
import CardHeaderButtonAI from "@/aiBetaBench/components/CardHeaderButtonAI";
import { MenuComponent } from "@/assets/ts/components";
import { ElNotification } from "element-plus";
import { capitalizeFirstLetter } from "@/utils/staticHelper";

export default {
  name: "AccountManagement",
  components: {
    CardToolbar,
    CardHeaderButtonAI,
    Field,
  },
  setup() {
    const loading = ref(false);
    const sortBy = ref(null);
    const sortDir = ref(null);
    const users = ref([]);
    const usersWithoutPagination = ref([]);
    const selectedUser = ref({});
    const localPagination = ref({
      offset: 1,
      limit: 10,
      total: 10,
    });
    const filterQuery = ref(SearchQueryEnum.ALL);

    onMounted(async () => {
      setTimeout(() => MenuComponent.reinitialization(), 0);
      await getUsers();
    });

    const filteredCategory = (category) => {
      delete category["OWNER"];
      return category;
    };

    const getUsers = async () => {
      try {
        loading.value = true;
        const { data } = await getAllUsers(filterQuery.value);
        for (const user of data.data) {
          if (user.email) {
            const payload = {
              id: user.id,
              uuid: user.uuid,
              email: user.email,
              isApproved: user.isApproved,
              category:
                user.role && user.role.category
                  ? user.role.category
                  : Category.USER,
            };
            usersWithoutPagination.value.push(payload);
          }
        }
        localPagination.value = {
          offset: 1,
          limit: 10,
          total: usersWithoutPagination.value.length,
        };
        paginationChanged();
      } catch (e) {
        console.log(e);
      } finally {
        loading.value = false;
      }
    };

    const applyFilter = () => {
      usersWithoutPagination.value = [];
      users.value = [];
      getUsers();
    };

    const updateUser = async () => {
      try {
        loading.value = true;
        const { data } = await updateUserAccount(selectedUser.value);
        if (data.status === 200) {
          selectedUser.value = data.data;
          ElNotification({
            title: "Success",
            message: data.message,
            type: "success",
          });
        } else {
          selectedUser.value = {};
          ElNotification({
            title: "Error",
            message: "Oops ! there is an error",
            type: "error",
          });
        }
      } catch (e) {
        console.log(e);
        ElNotification({
          title: "Error",
          message: "Oops ! there is an error",
          type: "error",
        });
      } finally {
        loading.value = false;
      }
    };
    const openEditModal = (user) => {
      selectedUser.value = user;
    };
    const paginationChanged = () => {
      users.value = usersWithoutPagination.value;
      users.value = paginate(
        users.value,
        localPagination.value.limit,
        localPagination.value.offset
      );
    };
    const sortResults = ({ prop, order }) => {
      sortBy.value = prop;
      sortDir.value = order;
      usersWithoutPagination.value = sortAllDataIfRequired(
        JSON.parse(JSON.stringify(usersWithoutPagination.value))
      );
      paginationChanged();
    };
    const sortAllDataIfRequired = (array) => {
      if (sortBy.value && sortDir.value) {
        return sortArray(sortBy.value, sortDir.value, array);
      } else {
        return array;
      }
    };

    const tableRowClassName = ({ rowIndex }) => {
      return rowIndex % 2 === 0
        ? "table-data-text"
        : "table-data-text striped-table-row";
    };
    const tableCellClassName = ({ columnIndex }) => {
      if (columnIndex === 0 || columnIndex === 1) {
        return "table-column-ellipses";
      } else {
        return "";
      }
    };
    return {
      loading,
      users,
      selectedUser,
      localPagination,
      Category,
      SearchQueryEnum,
      filterQuery,
      filteredCategory,
      updateUser,
      applyFilter,
      paginationChanged,
      sortResults,
      capitalizeFirstLetter,
      openEditModal,
      tableRowClassName,
      tableCellClassName,
    };
  },
};
</script>
<style lang="scss" scoped>
.approved-label {
  color: #1bc5bd;
  background-color: #c9f7f5;
  border-radius: 1rem;
  padding: 1rem 0.75rem;
}

.danger-label {
  color: #f64e60;
  background-color: #ffe2e5;
  border-radius: 1rem;
  padding: 1rem 0.75rem;
}

#edit_user_modal {
  .modal-dialog {
    .modal-content {
      .modal-header {
        border-bottom: none !important;

        .card-heading {
          color: #33647f !important;
          font-weight: 600 !important;
        }
      }
    }

    .modal-footer {
      border-top: none !important;
    }
  }

  .switch-text {
    color: #33647f !important;
    font-weight: 600 !important;
    vertical-align: middle;
    cursor: pointer;
  }

  .slider-text {
    color: #33647f !important;
    font-weight: 600 !important;
  }
}
</style>
